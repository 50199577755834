<template>
  <NuxtLink v-if="link" :href="link" class="card-container">
    <TwicPicture
      v-if="priority"
      class="card-image"
      :src="image"
      alt="Item Image"
      focus="auto"
      :sizes="`
        ${width}px
      `"
      ratio="2.2:1, @sm 3:1, @md 3:1, @lg 2:1"
      eager
    />
    <TwicImg
      v-if="!priority"
      :src="image"
      alt="Item Image"
      class="card-image"
      focus="auto"
      ratio="none"
    />
    <div class="gradient" />
    <slot />
  </NuxtLink>
  <div v-else class="card-container">
    <TwicImg :src="image" alt="Item Image" class="card-image" focus="auto" ratio="none" />
    <div class="gradient" />
    <slot />
  </div>
</template>

<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    image: string;
    height?: number;
    width?: number;
    fluid?: boolean;
    link?: string;
    priority?: boolean;
  }>(),
  {
    height: 630,
    width: 310,
    fluid: false,
    link: undefined,
    priority: false
  }
);

const widthData = computed(() => (props.fluid ? '100%' : `${props.width}px`));
const heightData = computed(() => `${props.height}px`);
</script>

<style lang="scss" scoped>
@use '$/border-radius.scss';
@use '$/misc.scss';
@use '$/breakpoints.scss';

.card-container {
  cursor: pointer;

  position: relative;

  flex-direction: column;
  justify-content: flex-end;

  width: v-bind(widthData);
  height: v-bind(heightData);

  border-radius: border-radius.$small;

  .gradient {
    @include misc.gradient();

    border-radius: border-radius.$small;
  }

  .card-image {
    @include misc.twic-pics-border-radius(border-radius.$small);

    position: absolute;
    transform: translateZ(0);

    width: 100%;
    height: 100%;

    object-fit: cover;
    background: linear-gradient(1deg, #000 0.3%, rgb(0 0 0 / 0%) 20%);
    border-radius: border-radius.$small;
  }

  &:hover:deep(.wrapper) {
    .bottom-container {
      display: flex;
    }

    .favorite-title {
      display: none;
    }
  }
}

@include breakpoints.mobile() {
  .card-container {
    width: v-bind(widthData);

    &:deep(.wrapper) {
      .bottom-container {
        display: flex;
      }

      .favorite-title {
        display: none;
      }
    }
  }
}
</style>
